import React from 'react'

function Footer() {
  return (
    <>
      <footer className="footer has-background-light is-narrow ">
          <div className="content has-text-centered">
              <p className="has-text-dark  is-size-6 is-size-7-mobile">
                  Copyright © 2024 Softech Solutions Web All rights reserved. [Designed and Developed by : Sandip Chakraborty]
              </p>
          </div>
      </footer>
    </>
  )
}

export default Footer