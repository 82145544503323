import React from 'react'


function Topbar({parameters}) {  
  return (
    <>
      <nav className="level has-background-light mb-0 px-3 py-3">
        {/* Left Side */}
        <div className="level-left">
          <div className="level-item">
            <img src={parameters.logo} />          
          </div>
          <div className="level-item">
            <div>
              <p className="is-size-3-desktop is-size-5-mobile  college-name-font">
              {parameters.appName}
              </p>
              <p className="is-size-6">
              {parameters.appSlogan}
              </p>                            
            </div>
          </div>
        </div>
        {/* Right side */}
        <div className="level-right has-text-centered-mobile has-text-right">
          <div>
            <p className="has-text-danger-60 is-size-3-desktop is-size-4-mobile has-text-weight-bold">
            {parameters.moduleName}
            </p>            
          </div>
        </div>
      </nav>
    </>
  )
}

export default Topbar