import React, { useEffect } from "react";
import Topbar from "./Layout/Topbar";
import Footer from "./Layout/Footer";
import ScrollToTop from "react-scroll-to-top";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faHouse } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

let renderCount = 0;
function AdminLogin() {
  // To Change the Title of the Page Dynamically
  useEffect(() => {
    // Update the title when the component mounts
    document.title = "Online Player Auction Portal || Admin Login";
  },[]); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      eauth: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data.username,data.eauth);
    const URLAPI = "http://api-server.local/restapicontroller"
    // const URLAPI = "https://webapps.sxccal.edu/accounts/checkloginapi"
    //const URLAPI = "https://cricketmanager.in/API/checkLoginAPI.php"
    
    axios.get(URLAPI, {
      username: data.username,
      eauth: data.eauth
    })
    .then(function (response) {
      console.log(response.data.message);
    })
    .catch(function (error) {
      console.log(error);
    });
  };
  //console.log(watch([])) // watch input value by passing the name of it

  const topbarParameter = {
    logo: "/assets/img/logo.png",
    appName: "Online Player Auction Portal",
    appSlogan: "Make Auction Easy ...",
    moduleName: "Admin Login",
  };

  return (
    <>
      <header>
        <Topbar parameters={topbarParameter} />
      </header>
      <section className="section">
        <div className="container is-fluid-mobile">
          {/* Form Start */}
          <div className="columns is-centered">
            <div className="column is-4">
              <div className="box ">
                <div className="title">Sign In</div>
                <hr />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field error">
                    <label className="label">Username</label>
                    <div className="control">
                      <input
                        className={`input ${errors.username && "is-danger"}`}
                        type="text"
                        placeholder="Enter User Name"
                        {...register("username", {
                          required: {
                            value: true,
                            message: "Username is required",
                          },
                        })}
                      />
                      <p className="has-text-danger mt-2">
                        {errors.username && (
                          <>
                            <FontAwesomeIcon icon={faExclamation} />
                            {" " + errors.username.message}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="field error">
                    <label className="label">Password</label>
                    <div className="control">
                      <input
                        className={`input ${errors.eauth && "is-danger"}`}
                        type="password"
                        placeholder="Enter Password"
                        {...register("eauth", {
                          required: {
                            value: true,
                            message: "Password is required",
                          },
                        })}
                      />
                      <p className="has-text-danger mt-2">
                        {errors.eauth && (
                          <>
                            <FontAwesomeIcon icon={faExclamation} />
                            {" " + errors.eauth.message}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="field is-grouped is-grouped-right">
                    <div className="control">
                      <button type="submit" className="button is-primary mr-2">
                        Login
                      </button>
                      <button
                        type="reset"
                        className="button is-danger has-text-white"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
                {/* Enter Message Loader Here */}
              </div>
            </div>
          </div>
          {/* Form End */}
        </div>
      </section>
      <Footer />
      <ScrollToTop smooth />
    </>
  );
}

export default AdminLogin;
